// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const DATA_TABLE = gql`
  query ExportDossierTypeListByNavigationSlug(
    $navigationSlug: String!,
    $dossierTypeSlug: String!,
    $sorters: [DataGridSorterInput]
    $filters: [DataGridFilterInput]
  ) {
    export: exportDossierTypeListByNavigationSlug(
      navigationSlug: $navigationSlug
      dossierTypeSlug: $dossierTypeSlug
      sorters: $sorters
      filters: $filters
    ) {
      name
      data
    }
  }
`;

export default DATA_TABLE;
